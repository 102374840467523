import React from "react";
import { Pagination } from "react-bootstrap";
import { If } from "../If.component";

export const TablePagination = (p: {
  total_page: number;
  active_page: number;
  onClick: (page: number) => void;
}) => {
  const total_show_pages = React.useMemo(() => 5, []);
  const middle_pages = React.useMemo(() => {
    const arr = Array<Number>();
    let total = total_show_pages;
    let iter = 0;
    while (total) {
      const page_num = iter + p.active_page - Math.floor(total_show_pages / 2);
      if (page_num >= p.total_page) break;
      if (page_num <= 1) {
        iter++;
        continue;
      }
      arr.push(page_num);
      iter++;
      total--;
    }
    return arr;
  }, [total_show_pages, p.active_page, p.total_page]);

  return (
    <Pagination>
      <If condition={p.total_page > 1}>
        <Pagination.First
          onClick={(e) => {
            p.onClick(1);
            e.currentTarget.blur();
          }}
        />
        <Pagination.Prev
          onClick={(e) => {
            if (p.active_page === 1) return;
            p.onClick(p.active_page - 1);
            e.currentTarget.blur();
          }}
        />

        <Pagination.Item
          active={p.active_page === 1}
          onClick={(e) => {
            p.onClick(1);
            e.currentTarget.blur();
          }}
        >
          {1}
        </Pagination.Item>

        <If condition={p.active_page >= total_show_pages}>
          <Pagination.Ellipsis />
        </If>

        {middle_pages.map((page_num, i) => {
          return (
            <Pagination.Item
              key={i}
              active={p.active_page === page_num}
              onClick={(e) => {
                p.onClick(Number(page_num));
                e.currentTarget.blur();
              }}
            >
              {Number(page_num)}
            </Pagination.Item>
          );
        })}

        <If condition={p.total_page - p.active_page > total_show_pages}>
          <Pagination.Ellipsis />
        </If>

        <Pagination.Item
          active={p.active_page === p.total_page}
          onClick={(e) => {
            p.onClick(Number(p.total_page));
            e.currentTarget.blur();
          }}
        >
          {p.total_page}
        </Pagination.Item>

        <Pagination.Next
          onClick={(e) => {
            if (p.active_page === p.total_page) return;
            p.onClick(p.active_page + 1);
            e.currentTarget.blur();
          }}
        />
        <Pagination.Last
          onClick={(e) => {
            p.onClick(p.total_page);
            e.currentTarget.blur();
          }}
        />
      </If>
    </Pagination>
  );
};
