import React from "react";
import { Button } from "react-bootstrap";
import { carwashApi_MembershipActivation } from "../../../carwashApi";
import { isString } from "lodash";

export const MembershipActivateButton = (p: {
  membership_id: string;
  onActivationSuccess: () => void;
}) => {
  return (
    <Button
      size="sm"
      onClick={() => {
        carwashApi_MembershipActivation({
          membership_id: p.membership_id,
        })
          .then(() => {
            p.onActivationSuccess();
          })
          .catch((e) => {
            alert(
              e?.response?.data?.message
                ? isString(e.response.data.message)
                  ? e.response.data.message
                  : e.response.data.message.issues?.[0]?.path?.[0] +
                    ": " +
                    e.response.data.message.issues?.[0]?.message
                : e
            );
          });
      }}
    >
      Activate
    </Button>
  );
};
