import React from "react";
import {
  Col,
  Container,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDebounce } from "use-debounce";

export interface IMembershipFilterForm {
  plate_number: string;
  customer_phone_number: string;
  nomor_kartu: string;
}

export const MembershipFilter = (p: {
  onFilter: (data: IMembershipFilterForm) => void;
}) => {
  const formHook = useForm<IMembershipFilterForm>();

  const [watchAllFieldsDebounce] = useDebounce(formHook.watch(), 1000);
  React.useEffect(() => {
    p.onFilter(watchAllFieldsDebounce);
  }, [watchAllFieldsDebounce]);

  return (
    <Container
      style={{
        borderBottomWidth: "1px",
        borderBottomColor: "black",
        borderBottomStyle: "dashed",
        marginBottom: "10px",
        paddingBottom: "10px",
      }}
    >
      <Form
        onSubmit={(event) => {
          formHook.clearErrors();
          formHook.handleSubmit((data) => {
            p.onFilter(data);
          })(event);
        }}
      >
        <FormGroup as={Row} controlId="Phone Number">
          <FormLabel column>Phone Number</FormLabel>
          <Col>
            <FormControl
              {...formHook.register("customer_phone_number")}
              placeholder="Find by Phone Number"
            />
            {formHook.formState.errors.customer_phone_number ? (
              <p style={{ color: "red" }}>
                {formHook.formState.errors.customer_phone_number.message}
              </p>
            ) : (
              ""
            )}
          </Col>
        </FormGroup>
        <FormGroup as={Row} controlId="Plate Number">
          <FormLabel column>Plate Number</FormLabel>
          <Col>
            <FormControl
              {...formHook.register("plate_number")}
              placeholder="Find by Plate Number"
            />
            {formHook.formState.errors.plate_number ? (
              <p style={{ color: "red" }}>
                {formHook.formState.errors.plate_number.message}
              </p>
            ) : (
              ""
            )}
          </Col>
        </FormGroup>
        <FormGroup as={Row} controlId="nomor_kartu">
          <FormLabel column>Nomor Kartu</FormLabel>
          <Col>
            <FormControl
              {...formHook.register("nomor_kartu")}
              placeholder="Find by Nomor Kartu"
            />
            {formHook.formState.errors.nomor_kartu ? (
              <p style={{ color: "red" }}>
                {formHook.formState.errors.nomor_kartu.message}
              </p>
            ) : (
              ""
            )}
          </Col>
        </FormGroup>
      </Form>
    </Container>
  );
};
