import React from "react";
import { Nav, NavDropdown } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { getCarwashToken } from "../carwashApi";

export const NavigationComponent = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!getCarwashToken()) {
      return navigate("/login");
    }
    navigate(pathname);
  }, [pathname, getCarwashToken]);

  return (
    <Nav
      className="flex-column"
      variant="pills"
      style={{
        backgroundColor: "honeydew",
        height: "100vh",
        overflowY: "scroll",
      }}
      activeKey={pathname}
      onSelect={(eventKey) => {
        if (eventKey) navigate(eventKey);
      }}
    >
      <p>0.0.1.2</p>
      <Nav.Item>
        <Nav.Link eventKey={"/home"}>Home</Nav.Link>
        <Nav.Link eventKey={"/membership"}>Membership</Nav.Link>
        <Nav.Link eventKey={"/transaction"}>Transactions</Nav.Link>
        <Nav.Link eventKey={"/login"}>Login</Nav.Link>
      </Nav.Item>
    </Nav>
  );
};
