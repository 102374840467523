import {
  FieldPath,
  FieldPathValue,
  FieldValues,
  PathValue,
  UseFormReturn,
} from "react-hook-form";
import { isArray } from "lodash";
import {
  QueryFunction,
  QueryFunctionContext,
  QueryKey,
  useQuery,
} from "react-query";
import { useDebounce } from "use-debounce";

export const useWatchAndQuery = <
  TFieldValues extends FieldValues = FieldValues,
  TContext = any,
  TTransformedValues extends FieldValues | undefined = undefined,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  TQueryFnData = unknown,
  TQueryKey extends QueryKey = QueryKey
>(p: {
  formHook: UseFormReturn<TFieldValues, TContext, TTransformedValues>;
  watchKey: TFieldName;
  queryKey: TQueryKey;
  queryFn: (
    watchData: FieldPathValue<TFieldValues, TFieldName>
  ) => TQueryFnData | Promise<TQueryFnData>;
  debouncDelay?: number;
  // test?: (x: TFieldName, y: TFieldValues[TFieldName]) => void;
}) => {
  const [watchFieldData] = useDebounce(
    p.formHook.watch(p.watchKey),
    p.debouncDelay || 1000
  );
  const qk = (() => {
    if (isArray(p.queryKey)) {
      // p.queryKey
      return [...p.queryKey, watchFieldData];
    }
    return [String(p.queryKey), watchFieldData];
  })();

  const queryApi = useQuery<TQueryFnData>(
    qk,
    async () => p.queryFn(watchFieldData),
    {
      enabled: !!watchFieldData,
      retry: false,
    }
  );
  return queryApi;
};
