import React from "react";
import { Container, Row, Table } from "react-bootstrap";
import { useQuery } from "react-query";
import { carwashApi_getTransactions } from "../carwashApi";
import { TablePagination } from "../components/TableComponent/TablePagination.component";
import { DateTime } from "luxon";
import {
  ITransactionFilterForm,
  TransactionFilter,
} from "../components/transactionPages/TransactionFilter.component";

export const TransactionPage = () => {
  const [activePage, setActivePage] = React.useState(1);
  const [rowPerPage] = React.useState(10);
  const [filterData, setFilterData] = React.useState<ITransactionFilterForm>();
  const transactionQuery = useQuery(
    ["transactions", activePage, rowPerPage, filterData],
    async () => {
      console.log(
        filterData,
        filterData?.transaction_items.transaction_item_name
      );
      const res = await carwashApi_getTransactions({
        limit: rowPerPage,
        skip: rowPerPage * (activePage - 1),
        transaction_date_max: filterData?.transaction_date_max
          ? DateTime.fromJSDate(filterData?.transaction_date_max)
              .set({ hour: 23, minute: 59, second: 59 })
              .toFormat("yyyy-MM-dd'T'HH:mm:ss")
          : undefined,
        transaction_date_min: filterData?.transaction_date_min
          ? DateTime.fromJSDate(filterData?.transaction_date_min)
              .set({ hour: 0, minute: 0, second: 0 })
              .toFormat("yyyy-MM-dd'T'HH:mm:ss")
          : undefined,
        "transaction_items.transaction_item_name":
          filterData?.transaction_items.transaction_item_name || undefined,
      });
      return res.data;
    },
    { retry: false }
  );
  return (
    <Container fluid>
      <TransactionFilter
        onFilter={(data) => {
          setFilterData({ ...data });
        }}
      />
      <Row style={{ paddingTop: "10px" }}>
        <h3>Total Data: {transactionQuery.data?.count || ""}</h3>
        <Table striped bordered>
          <thead>
            <tr>
              <th>Customer Name</th>
              <th>Customer Phone Number</th>
              <th>Plate Number</th>
              <th>Transaction Item</th>
              <th>Transaction Date</th>
              <th>Amount</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {transactionQuery.data?.data.map((data) => (
              <tr>
                <td>{data.customer.customer_name}</td>
                <td>{data.customer.customer_phone_number}</td>
                <td>{data.car.plate_number}</td>
                <td>
                  {data.transaction_items.map((transaction_item) => (
                    <Table>
                      <tbody>
                        <tr>
                          <td>Item Name</td>
                          <td>{transaction_item.transaction_item_name}</td>
                        </tr>
                        <tr>
                          <td>Item Price</td>
                          <td>{transaction_item.transaction_item_price}</td>
                        </tr>
                      </tbody>
                    </Table>
                  ))}
                </td>
                <td>
                  {DateTime.fromJSDate(
                    new Date(data.transaction_date)
                  ).toFormat("yyyy-MM-dd HH:mm:ss")}
                </td>
                <td>{data.transaction_amount}</td>
                <td>{data.transaction_status}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <TablePagination
          total_page={Math.ceil(
            (transactionQuery.data?.count || 0) / rowPerPage
          )}
          // total_page={10}
          active_page={activePage}
          onClick={(page) => {
            setActivePage(page);
          }}
        />
      </Row>
    </Container>
  );
};
