import axios from "axios";
import { isNull, isNumber, isUndefined, omitBy } from "lodash";
import { QueryFunctionContext } from "react-query";

function objectToQueryParams<T extends Object>(p: T) {
  return new URLSearchParams(
    Object(
      omitBy(p, (x) => isNull(x) || isUndefined(x) || (isNumber(x) && isNaN(x)))
    )
  ).toString();
}

let carwashToken = "";
export const setCarwashToken = (token: string) => {
  carwashToken = token;
};

export const getCarwashToken = () => {
  return carwashToken;
};

const carwashAxios = axios.create({
  // baseURL: "http://localhost/api",
  baseURL: "/api",
});

carwashAxios.interceptors.request.use((config) => {
  const token = getCarwashToken();
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

export const MembershipInfoTypes = ["cuci-unlimited"] as const;
export type MembershipInfoTypesType = (typeof MembershipInfoTypes)[number];

export const MembershipInfoStatuses = ["active", "inactive"] as const;
export type MembershipInfoStatusType = (typeof MembershipInfoStatuses)[number];

export interface IParamsCreateMembership {
  customer_id: string;
  member_type: MembershipInfoTypesType;
  expire_date: Date;
  issue_date?: Date;
  // cars?: Omit<IParamsCreateCar, "customer_id">[];
  plate_numbers?: string[];
  membership_nomor_kartus?: string[];
  price?: number;
  duration?: number;
}
export interface IParamsUpdateMembership {
  membership_id: string;
  customer_id?: string;
  expire_date?: Date;
  issue_date?: Date;
  // cars?: Omit<IParamsCreateCar, "customer_id">[];
  plate_numbers?: string[];
  membership_nomor_kartus?: string[];
  price?: number;
  duration?: number;
}
export interface IReturnCreateOrUpdateMembership {
  membership_id: string;
  member_type: MembershipInfoTypesType;
  status: MembershipInfoStatusType;
  expire_date: Date;
  issue_date: Date;
  last_usage_date: Date;
  membership_nomor_kartus?: string[];
}
export const carwashApi_createMembership = (d: IParamsCreateMembership) => {
  return carwashAxios.post<IReturnCreateOrUpdateMembership>("/memberships", d);
};

export const carwashApi_updateMembership = (d: IParamsUpdateMembership) => {
  const { membership_id, ...data } = d;
  return carwashAxios.put<IReturnCreateOrUpdateMembership>(
    `/memberships/${membership_id}`,
    data
  );
};

export const carwashApi_deleteMembership = async (membership_id: string) => {
  return carwashAxios.delete<IReturnCreateOrUpdateMembership>(
    `/memberships/${membership_id}`
  );
};

export interface IParamsGetMembership {
  sort?: string;
  limit?: number;
  skip?: number;
  select?: ("customer" | "cars" | "membership_info_kartus")[];
  "cars.plate_number"?: string;
  "cars.plate_number_like"?: string;
  "customer.customer_phone_number"?: string;
  "customer.customer_phone_number_like"?: string;
  "customer.customer_name_like"?: string;
  "membership_info_kartus.nomor_kartu"?: string;
  "membership_info_kartus.nomor_kartu_like"?: string;
}
export interface IReturnGetMembeship {
  membership_id: string;
  member_type: MembershipInfoTypesType;
  status: MembershipInfoStatusType;
  expire_date: Date;
  issue_date: Date;
  last_usage_date: Date;
  membership_info_kartus?: {
    nomor_kartu: string;
  }[];
  customer?: IReturnGetCustomers;
  cars?: IReturnGetCars[];
  price?: number;
  duration?: number;
}
export const carwashApi_getMembership = (d: IParamsGetMembership) => {
  const query = objectToQueryParams(d);
  console.log("get memberships query:", query);
  return carwashAxios.get<{ data: IReturnGetMembeship[]; count: number }>(
    "/memberships/?" + query
  );
};

export interface IParamsCreateCar {
  plate_number: string;
  car_brand?: string;
  color?: string;
  customer_id?: string;
}
export interface IReturnCreateCar {
  plate_number: string;
  car_brand?: string;
  color?: string;
  customers: {
    customer_id: string;
    customer_name: string;
    customer_phone_number: string;
    created_at: Date;
  }[];
  memberships?: {
    membership_id: string;
    member_type: MembershipInfoTypesType;
    status: MembershipInfoStatusType;
    expire_date: Date;
    issue_date: Date;
    last_usage_date: Date;
    customer: {
      customer_id: string;
      customer_name: string;
      customer_phone_number: string;
      created_at: Date;
    };
  }[];
}
export const carwashApi_createCar = (d: IParamsCreateCar) => {
  return carwashAxios.post<IReturnCreateCar>("/car", d);
};

export interface IParamGetCars {
  sort?: string;
  limit?: string;
  skip?: string;
  plate_number?: string;
  plate_number_like?: string;
}
export interface IReturnGetCars {
  plate_number: string;
  car_brand: string;
  car_color: string;
  created_at: Date;
  customers?: {
    customer_id: string;
    customer_name: string;
    customer_phone_number: string;
    created_at: Date;
  }[];
  memberships?: {
    membership_id: string;
    member_type: MembershipInfoTypesType;
    status: MembershipInfoStatusType;
    expire_date: Date;
    issue_date: Date;
    last_usage_date: Date;
    customer: {
      customer_id: string;
      customer_name: string;
      customer_phone_number: string;
      created_at: Date;
    };
  }[];
}
export const carwashApi_getCars = (p: IParamGetCars) => {
  const query = objectToQueryParams(p);
  return carwashAxios.get<{ data: IReturnGetCars[]; count: number }>(
    "/car/?" + query
  );
};

export interface IParamsPushCarCustomer {
  plate_number: string;
  customer_id: string;
}
export const carwashApi_pushCarCustomer = (p: IParamsPushCarCustomer) => {
  return carwashAxios.post("/car/push-customer", p);
};

export interface IParamsPushCarMembership {
  plate_number: string;
  membership_id: string;
}
export const carwashApi_pushCarMembership = (p: IParamsPushCarMembership) => {
  return carwashAxios.post("/car/push-membership", p);
};

export interface IParamsCreateCustomer {
  customer_name: string;
  customer_phone_number: string;
  car_plate_numbers?: string[];
}
export interface IReturnCreateCustomer {
  customer_id: string;
  customer_name: string;
  customer_phone_number: string;
  created_at: Date;
  cars?: IReturnGetCars[];
}
export const carwashApi_createCustomer = (d: IParamsCreateCustomer) => {
  return carwashAxios.post<IReturnCreateCustomer>("/customer", d);
};

export interface IParamsUpdateCustomer {
  customer_id: string;
  customer_name: string;
  customer_phone_number: string;
  car_plate_numbers?: string[];
}
export const carwashApi_updateCustomer = (d: IParamsUpdateCustomer) => {
  const { customer_id, ...data } = d;
  return carwashAxios.put<IReturnCreateCustomer>(
    `/customer/${customer_id}`,
    data
  );
};

export interface IParamGetCustomers {
  sort?: string;
  limit?: string;
  skip?: string;
  customer_phone_number?: string;
  select?: ("memberships" | "cars" | "memberships.cars")[];
}
export interface IReturnGetCustomers {
  customer_id: string;
  customer_name: string;
  customer_phone_number: string;
  created_at: Date;
  memberships?: {
    membership_id: string;
    member_type: MembershipInfoTypesType;
    status: MembershipInfoStatusType;
    expire_date: Date;
    issue_date: Date;
    last_usage_date: Date;
    cars?: IReturnGetCars[];
  }[];
  cars?: IReturnGetCars[];
}
export const carwashApi_getCustomers = async (p: IParamGetCustomers) => {
  const query = objectToQueryParams(p);
  return carwashAxios.get<{
    data: IReturnGetCustomers[];
    count: number;
  }>("/customer/?" + query);
};

export const carwashApi_MembershipCuciUnlimitedUse = async (p: {
  membership_id: string;
  car_plate_number: string;
}) => {
  return carwashAxios.post<IReturnGetMembeship>(
    "/membership/cuci-unlimited",
    p
  );
};

export const carwashApi_MembershipActivation = async (p: {
  membership_id: string;
}) => {
  return carwashAxios.post(`/membership-activate/${p.membership_id}`);
};

export const carwashApi_GetTotalAciveMemberships = async () => {
  return carwashAxios.get<{ totalActiveMembers: number }>(
    "/memberships/total-active"
  );
};

export const carwashApi_GetTotalMembershipTransaction = async (p: {
  transaction_date_min: Date;
  transaction_date_max: Date;
}) => {
  const query = objectToQueryParams(p);
  return carwashAxios.get<{ totalTransaction: number }>(
    "/transaction/total-membership?" + query
  );
};

export interface IParamGetTransactions {
  sort?: string;
  limit?: number;
  skip?: number;
  transaction_type?: string;
  transaction_date_max?: string;
  transaction_date_min?: string;
  "transaction_items.transaction_item_name"?: string;
}

export const TransactionTypes = [
  "member",
  "normal",
  "coupon",
  "promo-opening",
  "member-cuci-unlimited",
] as const;
export type TransactionTypesType = (typeof TransactionTypes)[number];

export const TransactionStatuses = ["unpaid", "paid"] as const;
export type TransactionStatusType = (typeof TransactionStatuses)[number];
export interface IReturnGetTransaction {
  transaction_id: string;
  transaction_type: TransactionTypesType;
  transaction_date: Date;
  transaction_amount: number;
  transaction_status: TransactionStatusType;
  transaction_items: {
    id: number;
    transaction_item_name: string;
    transaction_item_price: number;
  }[];
  customer: IReturnGetCustomers;
  car: IReturnGetCars;
}

export const carwashApi_getTransactions = async (p: IParamGetTransactions) => {
  const query = objectToQueryParams(p);
  console.log(p, query);
  return carwashAxios.get<{ data: IReturnGetTransaction[]; count: number }>(
    "/transaction?" + query
  );
};
