import axios from "axios";

const authAxios = axios.create({
  // baseURL: "http://localhost/api",
  baseURL: "/",
});

export const login = (d: { password: string }) => {
  return authAxios.post("/auth", d);
};
