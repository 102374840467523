import React from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  FormSelect,
  Row,
} from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { TransactionItemOptions } from "./TransactionItemOptions.component";
import { useDebounce } from "use-debounce";
import ReactDatePicker from "react-datepicker";

export interface ITransactionFilterForm {
  transaction_items: { transaction_item_name: string };
  transaction_date_min: Date;
  transaction_date_max: Date;
}

export const TransactionFilter = (p: {
  onFilter: (d: ITransactionFilterForm) => void;
}) => {
  const { register, watch, control } = useForm<ITransactionFilterForm>();

  const [watchAllFieldsDebounce] = useDebounce(watch(), 1000);
  React.useEffect(() => {
    p.onFilter(watchAllFieldsDebounce);
  }, [watchAllFieldsDebounce]);

  return (
    <Container
      style={{
        borderBottomWidth: "1px",
        borderBottomColor: "black",
        borderBottomStyle: "dashed",
        marginBottom: "10px",
        paddingBottom: "10px",
      }}
    >
      <Form>
        <FormGroup>
          <FormLabel column>Item Name</FormLabel>
          <Col>
            <FormSelect
              {...register("transaction_items.transaction_item_name")}
              defaultValue={undefined}
            >
              <TransactionItemOptions />
            </FormSelect>
          </Col>
        </FormGroup>
        <FormGroup>
          <FormLabel column>Date</FormLabel>
          <Col>
            <Row>
              <Controller
                control={control}
                name="transaction_date_min"
                render={({ field }) => {
                  return (
                    <ReactDatePicker
                      placeholderText="transaction_date_min"
                      dateFormat={"yyyy-MM-dd"}
                      onChange={(date) => {
                        field.onChange(date);
                      }}
                      selected={field.value}
                    />
                  );
                }}
              />
            </Row>
            <Row>-</Row>
            <Row>
              <Controller
                control={control}
                name="transaction_date_max"
                render={({ field }) => {
                  return (
                    <ReactDatePicker
                      placeholderText="transaction_date_max"
                      dateFormat={"yyyy-MM-dd"}
                      onChange={(date) => {
                        field.onChange(date);
                      }}
                      selected={field.value}
                    />
                  );
                }}
              />
            </Row>
          </Col>
        </FormGroup>
      </Form>
    </Container>
  );
};
