import React from "react";
import {
  Button,
  Col,
  FormControl,
  FormGroup,
  FormLabel,
  FormSelect,
  Modal,
  Row,
} from "react-bootstrap";
import { MembershipDurationOptions } from "../MembershipDurationOptions.component";
import {
  IReturnCreateOrUpdateMembership,
  IReturnGetCustomers,
  IReturnGetMembeship,
  carwashApi_MembershipActivation,
  carwashApi_updateMembership,
} from "../../../carwashApi";
import { thousandCommas } from "../../../utilities";
import { DateTime } from "luxon";
import { isString } from "lodash";

const expire_date_calculation = (p: {
  membership: IReturnGetMembeship;
  issue_date: Date;
  duration: number;
}) => {
  const { membership, issue_date, duration } = p;
  if (membership && new Date(membership.expire_date) > issue_date) {
    return DateTime.fromJSDate(new Date(membership.expire_date))
      .plus({
        month: duration,
      })
      .set({
        hour: 23,
        minute: 59,
        second: 50,
      })
      .toJSDate();
  } else {
    return DateTime.fromJSDate(issue_date)
      .plus({ month: duration })
      .minus({ day: 1 })
      .set({ hour: 23, minute: 59, second: 50 })
      .toJSDate();
  }
};
export const MembershipExtendButton = (p: {
  membership: IReturnGetMembeship;
  onExtendSuccess: (r: IReturnCreateOrUpdateMembership) => void;
}) => {
  const [show, setShow] = React.useState(false);
  const [formData, setFormData] = React.useState<{
    duration: number;
    price: string;
    expireDate: Date | undefined;
  }>({ duration: 0, price: "", expireDate: undefined });
  return (
    <>
      <Button
        size="sm"
        onClick={() => {
          setShow(true);
          setFormData({ duration: 0, price: "", expireDate: undefined });
        }}
      >
        Extend
      </Button>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Extend Membership</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup as={Row} controlId="duration">
            <FormLabel column>Duration</FormLabel>
            <Col>
              <FormSelect
                defaultValue={0}
                value={formData.duration}
                onChange={(e) => {
                  formData.duration = parseInt(e.currentTarget.value);
                  formData.expireDate = expire_date_calculation({
                    membership: p.membership,
                    duration: formData.duration,
                    issue_date: DateTime.now().toJSDate(),
                  });

                  setFormData({ ...formData });
                }}
              >
                <MembershipDurationOptions />
              </FormSelect>
            </Col>
          </FormGroup>
          <FormGroup as={Row} controlId="price">
            <FormLabel column>Members Price</FormLabel>
            <Col>
              <FormControl
                onChange={(e) => {
                  formData.price = thousandCommas(
                    e.currentTarget.value.replaceAll(",", "")
                  );
                  setFormData({ ...formData });
                }}
                value={formData.price}
                placeholder="Price"
              />
            </Col>
          </FormGroup>
          <FormGroup as={Row} controlId="Expire Date">
            <FormLabel column>Expire Date</FormLabel>
            <Col>
              <FormControl
                disabled
                value={
                  formData.expireDate
                    ? DateTime.fromJSDate(formData.expireDate).toFormat(
                        "yyyy-MM-dd"
                      )
                    : ""
                }
              />
            </Col>
          </FormGroup>
          <Button
            onClick={() => {
              carwashApi_updateMembership({
                membership_id: p.membership.membership_id,
                expire_date: formData.expireDate,
                issue_date: DateTime.now()
                  .set({ hour: 0, minute: 0, second: 0 })
                  .toJSDate(),
                membership_nomor_kartus:
                  p.membership.membership_info_kartus
                    ?.map((n) => n.nomor_kartu)
                    .filter((n) => !!n) || [],
                duration: formData.duration,
                price: parseInt(formData.price.replaceAll(",", "")),
                plate_numbers: p.membership.cars?.map((c) => c.plate_number),
              })
                .then(async (r) => {
                  await carwashApi_MembershipActivation({
                    membership_id: r.data.membership_id,
                  }).catch();
                  alert("Extend Membership OK");
                  setShow(false);
                  setFormData({
                    duration: 0,
                    expireDate: undefined,
                    price: "",
                  });
                  p.onExtendSuccess(r.data);
                })
                .catch((e) => {
                  alert(
                    e?.response?.data?.message
                      ? isString(e.response.data.message)
                        ? e.response.data.message
                        : e.response.data.message.issues?.[0]?.path?.[0] +
                          ": " +
                          e.response.data.message.issues?.[0]?.message
                      : e
                  );
                });
            }}
          >
            Submit
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};
