import React from "react";
import logo from "./logo.svg";
import "./App.css";
import {
  BrowserRouter,
  createBrowserRouter,
  Navigate,
  Route,
  RouterProvider,
  Routes,
} from "react-router-dom";
import { HelloworldPage } from "./pages/helloworld.page";
import { Col, Container, Row } from "react-bootstrap";
import { NavigationComponent } from "./components/navigation.component";
import { HomePage } from "./pages/Home.page";
import "bootstrap/dist/css/bootstrap.min.css";
import { MembershipPage } from "./pages/Membership.page";
import { QueryClient, QueryClientProvider } from "react-query";
import axios from "axios";
import { LoginPage } from "./pages/Login.page";
import { TransactionPage } from "./pages/Transaction.page";

const router = createBrowserRouter([
  {
    path: "/hello",
    element: <HelloworldPage />,
  },
  {
    path: "/",
    element: <HomePage />,
  },
]);
const queryClient = new QueryClient();
function App() {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Container fluid style={{ padding: "0" }}>
          <Row>
            <Col lg="2">
              <NavigationComponent />
            </Col>
            <Col lg="10">
              <Routes>
                <Route path="/" element={<Navigate to={"/login"} />} />
                <Route path="/home" element={<HomePage />} />
                <Route path="/membership" element={<MembershipPage />} />
                <Route path="/hello" element={<HelloworldPage />} />
                <Route path="/transaction" element={<TransactionPage />} />
                <Route path="/login" element={<LoginPage />} />
              </Routes>
            </Col>
          </Row>
        </Container>
      </QueryClientProvider>
    </BrowserRouter>
  );
}

export default App;
