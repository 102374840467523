import react from "react";
import { Container, Table } from "react-bootstrap";

export const HomePage = () => {
  return (
    <Container fluid>
      <h3>Home</h3>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th></th>
          </tr>
        </thead>
      </Table>
    </Container>
  );
};
