import React from "react";
import { Button, Modal } from "react-bootstrap";
import {
  IReturnGetCars,
  IReturnGetMembeship,
  carwashApi_MembershipCuciUnlimitedUse,
} from "../../../carwashApi";
import { isString } from "lodash";

export const MembershipUsedButton = (p: {
  membership_id: string;
  cars: IReturnGetCars[];
  onUseSuccess: (data: IReturnGetMembeship) => void;
}) => {
  const [show, setShow] = React.useState(false);
  return (
    <>
      <Button
        size="sm"
        onClick={() => {
          setShow(true);
        }}
      >
        Use
      </Button>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Plate Numbers</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {p.cars.map((car) => (
            <Button
              onClick={() => {
                carwashApi_MembershipCuciUnlimitedUse({
                  car_plate_number: car.plate_number,
                  membership_id: p.membership_id,
                })
                  .then((r) => {
                    p.onUseSuccess(r.data);
                    setShow(false);
                  })
                  .catch((e) => {
                    alert(
                      e?.response?.data?.message
                        ? isString(e.response.data.message)
                          ? e.response.data.message
                          : e.response.data.message.issues?.[0]?.path?.[0] +
                            ": " +
                            e.response.data.message.issues?.[0]?.message
                        : e
                    );
                  });
              }}
            >
              {car.plate_number}
            </Button>
          ))}
        </Modal.Body>
      </Modal>
    </>
  );
};
