import React from "react";
import {
  Button,
  Col,
  Container,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { setCarwashToken } from "../carwashApi";
import { login } from "../authApi";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";

export const LoginPage = () => {
  const loginForm = useForm({
    values: {
      password: "",
    },
  });
  const navigate = useNavigate();
  const submitButtonRef = React.useRef<HTMLButtonElement>(null);

  return (
    <Container style={{ height: "100vh", alignContent: "center" }}>
      <Row>
        <FormGroup as={Row} controlId={`password`}>
          <FormControl
            {...loginForm.register(`password`)}
            placeholder="Password"
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                submitButtonRef.current?.click();
              }
            }}
          />
        </FormGroup>
      </Row>
      <br />
      <Row>
        <Button
          ref={submitButtonRef}
          onClick={(e) => {
            loginForm.handleSubmit((data) => {
              login({ password: data.password })
                .then(() => {
                  setCarwashToken(data.password);
                  navigate("/membership");
                })
                .catch((e: AxiosError) => {
                  alert((e.response?.data as any).message);
                });
              //   console.log("LOGIN", data);
            })(e);
          }}
        >
          Login
        </Button>
      </Row>
    </Container>
  );
};
